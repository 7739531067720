import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selectedEmail: null,
    verifiedEmailModalType: 'add',
  },
  getters: {
    getSelectedEmail: state => state.selectedEmail,
    getVerifiedEmailModalType: state => state.verifiedEmailModalType,
  },
  mutations: {
    SET_SELECTED_EMAIL: (state, val) => {
      state.selectedEmail = val
    },
    SET_VERIFIED_EMAIL_MODAL_TYPE: (state, val) => {
      state.verifiedEmailModalType = val
    },
  },
  actions: {
    fetchVerifiedEmails(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/verified-emails', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
