<template>
  <b-modal
    id="error-message-modal"
    ref="error-message-modal"
    title="Error message"
    centered
    no-close-on-backdrop
    ok-only
    ok-title="Save"
    cancel-variant="outline-secondary"
    size="sm"
    class="p-0"
    @show="setModalData"
    @ok="saveChanges"
  >
    <b-form-group
      label="Error message"
    >
      <b-form-input
        v-model="message"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'

import useVerifiedEmails from '@/views/admin/verified-emails/useVerifiedEmails'
import { setCurrentUserData } from '@/auth/utils'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
  },
  data() {
    return {
      message: '',
    }
  },
  setup() {
    const {
      postErrorMessage,
    } = useVerifiedEmails()

    return {
      postErrorMessage,
    }
  },
  methods: {
    setModalData() {
      const schoolData = JSON.parse(localStorage.getItem('schoolData'))
      this.message = schoolData.config.member_verification_error_message
    },
    async saveChanges(bvModalEvt) {
      bvModalEvt.preventDefault()

      await this.postErrorMessage({ error_message: this.message })
      await setCurrentUserData()
      this.$bvModal.hide('error-message-modal')
    },
  },
}
</script>

<style>

</style>
