<template>
  <!-- Group Bulk Assign-->
  <b-modal
    id="import-emails-modal"
    ref="import-emails-modal"
    centered
    title="Import Verified Emails"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @hide="resetModalData"
  >
    <form
      ref="form"
      class="px-4"
    >
      <b-row>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Select CSV File"
          >
            <b-form-file
              ref="refInputEl"
              v-model="importFile"
              accept=".csv"
              placeholder="Choose file"
              @change="handleFileChange"
            />
          </b-form-group>

          <b-link
            @click="exportExampleCSV"
          >
            <u>Download Template CSV</u>
          </b-link>
        </b-col>

      </b-row>
    </form>

    <div
      v-if="validationErrors.length"
      class="form-group pt-1"
    >
      <p
        v-for="(validationError, index) in validationErrors"
        :key="index"
        class="text-center text-danger"
      >
        {{ validationError }}
      </p>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          class="float-right"
          :disabled="!importFile || isLoading"
          @click="uploadFile"
        >
          <b-spinner
            v-if="isLoading"
            label="Spinning"
            small
          />
          <span v-else>Import Emails</span>
        </b-button>
      </div>
    </template>
  </b-modal>

</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormFile, BButton, BLink, BSpinner,
} from 'bootstrap-vue'

import { downloadExportFile } from '@core/mixins/downloadExportFile'
import useVerifiedEmails from '@/views/admin/verified-emails/useVerifiedEmails'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BButton,
    BLink,
    BSpinner,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      importFile: null,
      validationErrors: [],
      isLoading: false,
    }
  },
  setup() {
    const {
      importVerifiedEmails,
    } = useVerifiedEmails()

    return {
      importVerifiedEmails,
    }
  },
  methods: {
    handleFileChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.importFile = e.target.files[0]
    },
    async uploadFile() {
      const formData = new FormData()
      formData.append('file', this.importFile)

      this.validationErrors = []

      this.isLoading = true

      try {
        await this.importVerifiedEmails(formData)
        this.$emit('refetchData')
        this.$bvModal.hide('import-emails-modal')
      } finally {
        this.isLoading = false
      }
    },
    resetModalData() {
      this.importFile = null
    },
    async exportExampleCSV() {
      this.downloadFile(['test1@test.com\ntest2@test.com'], 'import_emails_template', 'csv')
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
