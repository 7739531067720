import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ref, watch } from '@vue/composition-api'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import store from '@/store'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'

export default function useVerifiedEmails(root) {
  // Use toast
  const toast = useToast()

  const refVerifiedEmailsList = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'email', label: 'Email', sortable: true },
    { key: 'updated_at', label: 'Last updated at', sortable: true },
    { key: 'updated_by', label: 'Last updated by', sortable: true },
    { key: 'actions' },
  ]

  const isVerifiedEmailsActive = ref(Boolean)
  const defaultPerPage = Number(localStorage.getItem('verifiedEmailsPerPage'))
  const perPage = ref(defaultPerPage || DEFAULT_PER_PAGE)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = PER_PAGE_OPTIONS
  const searchQuery = ref('')
  const sortBy = ref('created_at')
  const isSortDirDesc = ref(true)
  let debounceTimeout = ref(null)

  const dataMeta = ref({
    from: 0,
    to: 0,
    of: 0,
  })

  const refetchData = () => {
    refVerifiedEmailsList.value.refresh()
  }
  watch([currentPage, perPage, searchQuery],
    ([currentPageVal, perPageVal, searchQueryVal],
      [currentPagePrevVal, perPagePrevVal, searchQueryPrevVal]) => {
      if (currentPageVal !== currentPagePrevVal) {
        pushStateFiltersParams(root, 'admin-verified-emails', 'currentPage', currentPageVal)
      }
      if (perPageVal !== perPagePrevVal) {
        pushStateFiltersParams(root, 'admin-verified-emails', 'perPage', perPageVal)
      }
      if (searchQueryVal !== searchQueryPrevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(root, 'admin-verified-emails', 'searchQuery', searchQueryVal)
        }, 250)
      }
    })

  const fetchVerifiedEmails = (ctx, callback) => {
    const payloadData = {
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    }
    if (searchQuery.value) payloadData.email = searchQuery.value

    store
      .dispatch('app-verified-emails/fetchVerifiedEmails', payloadData)
      .then(response => {
        const { data } = response.data
        const { total, from, to } = response.data.meta
        callback(data)
        totalItems.value = total
        dataMeta.value = {
          from,
          to,
          of: total,
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Verified Emails' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const postVerifiedEmails = queryParams => axios
    .post('/auth/verified-emails', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Verified Email item has created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(error => {
      const { response } = error
      const title = Object.values(error.response.data.errors).join()
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const updateVerifiedEmail = queryParams => axios
    .put(`/auth/verified-emails/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Verified Email was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data.id
    })
    .catch(error => {
      const { response } = error
      const title = Object.values(error.response.data.errors).join()
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  const deleteVerifiedEmail = ID => axios
    .delete(`auth/verified-emails/${ID}`)
    .then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Verified Email item',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Verified Email item',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const postMemberVerificationStatus = queryParams => axios
    .post('/auth/verified-emails/member-verification', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Member verification status was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Member verification status',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const postErrorMessage = queryParams => axios
    .post('/auth/verified-emails/set-error-message', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error Message was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Error Message',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const exportVerifiedEmails = () => axios
    .get('/auth/verified-emails/export')
    .then(response => response)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error exporting Verified Emails',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const importVerifiedEmails = formData => axios
    .post('/auth/verified-emails/import', formData)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success importing Verified Emails',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error importing Verified Emails',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    tableColumns,
    isVerifiedEmailsActive,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    totalItems,
    isSortDirDesc,
    refVerifiedEmailsList,
    fetchVerifiedEmails,
    postVerifiedEmails,
    updateVerifiedEmail,
    deleteVerifiedEmail,
    postMemberVerificationStatus,
    postErrorMessage,
    exportVerifiedEmails,
    importVerifiedEmails,

    refetchData,
  }
}
