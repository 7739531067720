<template>
  <b-modal
    id="export-emails-modal"
    ref="export-emails-modal"
    ok-title="Save"
    cancel-variant="outline-secondary"
    centered
    title="Export emails"
    no-close-on-backdrop
    @show="setModalData"
    @ok="saveCSV"
    @hidden="resetModalData"
  >
    <TableSpinner v-if="isLoading" />
    <div v-else>
      <span v-if="errorMessage">{{ errorMessage }}</span>
      <span v-else>VerifiedEmails.csv</span>
    </div>
  </b-modal>
</template>

<script>
import { downloadExportFile } from '@core/mixins/downloadExportFile'

import useVerifiedEmails from '@/views/admin/verified-emails/useVerifiedEmails'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

export default {
  components: {
    TableSpinner,
  },
  mixins: [downloadExportFile],
  data() {
    return {
      isLoading: false,
      csvFile: null,
      errorMessage: '',
    }
  },
  setup() {
    const {
      exportVerifiedEmails,
    } = useVerifiedEmails()

    return {
      exportVerifiedEmails,
    }
  },
  methods: {
    async setModalData() {
      this.isLoading = true
      try {
        const response = await this.exportVerifiedEmails()
        if (response.status === 200) {
          this.csvFile = response.data
        }
      } catch (error) {
        this.errorMessage = 'Error exporting Verified Emails'
      } finally {
        this.isLoading = false
      }
    },
    saveCSV() {
      this.downloadFile(this.csvFile, 'VerifiedEmails', 'csv')
    },
    resetModalData() {
      this.csvFile = null
      this.errorMessage = ''
    },
  },
}
</script>

<style>

</style>
