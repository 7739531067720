<template>
  <b-modal
    id="verified-email-modal"
    ref="verified-email-modal"
    :title="title"
    centered
    no-close-on-backdrop
    ok-only
    :ok-title="title"
    cancel-variant="outline-secondary"
    size="sm"
    class="p-0"
    @show="setModalData"
    @ok="saveChanges"
  >
    <validation-observer
      ref="verifiedEmailModalObserver"
    >
      <b-form-group
        label="Email"
      >
        <validation-provider
          #default="{ errors }"
          name="Email"
          rules="required|email"
        >
          <b-form-input
            v-model="verifiedEmail"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { BModal, BFormGroup, BFormInput } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import useVerifiedEmails from '@/views/admin/verified-emails/useVerifiedEmails'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      verifiedEmail: '',
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      modalType: 'app-verified-emails/getVerifiedEmailModalType',
      selectedEmail: 'app-verified-emails/getSelectedEmail',
    }),
    title() {
      return this.modalType === 'edit' ? 'Edit Email' : 'Add New Email'
    },
  },
  setup() {
    const {
      postVerifiedEmails,
      updateVerifiedEmail,
    } = useVerifiedEmails()

    return {
      postVerifiedEmails,
      updateVerifiedEmail,
    }
  },
  methods: {
    setModalData() {
      if (this.modalType === 'edit') {
        this.verifiedEmail = this.selectedEmail.email
      } else {
        this.verifiedEmail = ''
      }
    },
    saveChanges(bvModalEvt) {
      bvModalEvt.preventDefault()

      return new Promise((resolve, reject) => {
        this.$refs.verifiedEmailModalObserver.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.modalType === 'add') {
              this.addNewEmail()
            } else {
              this.updateEmail()
            }
          } else {
            reject()
          }
        })
      })
    },
    async addNewEmail() {
      await this.postVerifiedEmails({ email: this.verifiedEmail })
      this.$emit('refetchData')
      this.$bvModal.hide('verified-email-modal')
    },
    async updateEmail() {
      await this.updateVerifiedEmail({ id: this.selectedEmail.id, email: this.verifiedEmail })
      this.$store.commit('app-verified-emails/SET_SELECTED_EMAIL', null)
      this.$emit('refetchData')
      this.$bvModal.hide('verified-email-modal')
    },
  },
}
</script>

<style>

</style>
